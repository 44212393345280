export default [
  {
    name: 'dashboard',
    path: '/dashboard',
    redirect: '/dashboard/bath',
    meta: { title: '仪表盘', icon: 'assets-slide-dashboard' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'dashboardbath',
        path: '/dashboard/bath',
        meta: { title: '仪表盘', parentTitle: '首页' },
        component: () => import('@/views/dashboard/index.vue'),
      },
    ],
  },
  {
    name: 'serve',
    path: '/serve',
    redirect: '/serve/bath',
    meta: { title: '企业服务', icon: 'assets-slide-form' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'servebath',
        path: '/serve/bath',
        meta: { title: '企业服务', parentTitle: '首页' },
        component: () => import('@/views/serve/index.vue'),
      },
    ],
  },
  {
    name: 'agentCompanyList',
    path: '/agentCompanyList',
    redirect: '/agentCompanyList/bath',
    meta: { title: '下级企业列表', icon: 'assets-slide-form' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'agentCompanyList',
        path: '/agentCompanyList/bath',
        meta: { title: '下级企业列表', parentTitle: '首页' },
        component: () => import('@/views/agent/agentCompanyList/index.vue'),
      },
    ],
  },
  {
    name: 'order',
    path: '/order',
    redirect: '/order/companyApply',
    meta: { title: '业务订单', icon: 'assets-slide-list' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'companyApply',
        path: '/order/companyApply',
        meta: { title: '企业申请', parentTitle: '首页' },
        component: () => import('@/views/order/companyApply/index.vue'),
      },
      {
        name: 'accountApply',
        path: '/order/accountApply',
        meta: { title: '开户申请', parentTitle: '首页' },
        component: () => import('@/views/order/accountApply/index.vue'),
      },
      {
        name: 'companyCancel',
        path: '/order/companyCancel',
        meta: { title: '企业注销', parentTitle: '首页' },
        component: () => import('@/views/order/companyCancel/index.vue'),
      },
      {
        name: 'paperApply',
        path: '/order/paperApply',
        meta: { title: '开票申请', parentTitle: '首页' },
        component: () => import('@/views/order/paperApply/index.vue'),
      },
      {
        name: 'increaseApply',
        path: '/order/increaseApply',
        meta: { title: '开票提额申请', parentTitle: '首页' },
        component: () => import('@/views/order/increase/index.vue'),
      },
    ],
  },
  {
    name: 'financial',
    path: '/financial',
    redirect: '/financial/recharge',
    meta: { title: '财务管理', icon: 'assets-slide-list' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'recharge',
        path: '/financial/recharge',
        meta: { title: '充值明细', parentTitle: '首页' },
        component: () => import('@/views/financial/recharge/index.vue'),
      },
      {
        name: 'consume',
        path: '/financial/consume',
        meta: { title: '消费明细', parentTitle: '首页' },
        component: () => import('@/views/financial/consume/index.vue'),
      },
      {
        name: 'nopay',
        path: '/financial/nopay',
        meta: { title: '挂账明细', parentTitle: '首页' },
        component: () => import('@/views/financial/nopay/index.vue'),
      },
      {
        name: 'taxReport',
        path: '/financial/taxReport',
        meta: { title: '税金报表', parentTitle: '首页' },
        component: () => import('@/views/financial/taxReport/index.vue'),
      },
    ],
  },
  {
    name: 'account',
    path: '/account',
    redirect: '/account/bath',
    meta: { title: '子账号管理', icon: 'assets-slide-form' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'accountbath',
        path: '/account/bath',
        meta: { title: '子账号管理', parentTitle: '首页' },
        component: () => import('@/views/account/index.vue'),
      },
    ],
  },
  {
    name: 'personalStep',
    path: '/personalStep',
    redirect: '/personalStep/bath',
    meta: { title: '企业申请', hidden: true },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'personalStepbath',
        path: '/personalStep/bath',
        meta: { title: '企业申请', parentTitle: '首页' },
        component: () => import('@/views/personalStep/index.vue'),
      },
    ],
  },
  {
    name: 'agent',
    path: '/agent',
    redirect: '/agent/agentList',
    meta: { title: '下级管理', icon: 'assets-slide-list' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'agentList',
        path: '/agent/agentList',
        meta: { title: '下级列表', parentTitle: '首页' },
        component: () => import('@/views/agent/agentList/index.vue'),
      },
      {
        name: 'agentApply',
        path: '/agent/agentApply',
        meta: { title: '企业申请审核', parentTitle: '首页' },
        component: () => import('@/views/agent/agentApply/index.vue'),
      },
      {
        name: 'agentAccountApply',
        path: '/agent/agentAccountApply',
        meta: { title: '开户申请审核', parentTitle: '首页' },
        component: () => import('@/views/agent/agentAccountApply/index.vue'),
      },
      {
        name: 'agentCancelApply',
        path: '/agent/agentCancelApply',
        meta: { title: '注销申请审核', parentTitle: '首页' },
        component: () => import('@/views/agent/agentCancelApply/index.vue'),
      },
      {
        name: 'agentPaperApply',
        path: '/agent/agentPaperApply',
        meta: { title: '开票申请审核', parentTitle: '首页' },
        component: () => import('@/views/agent/agentPaperApply/index.vue'),
      },
      {
        name: 'agentBalance',
        path: '/agent/agentBalance',
        meta: { title: '渠道余额信息', parentTitle: '首页' },
        component: () => import('@/views/agent/agentBalance/index.vue'),
      },
      {
        name: 'agentDebt',
        path: '/agent/agentDebt',
        meta: { title: '待核销订单', parentTitle: '首页' },
        component: () => import('@/views/agent/agentDebt/index.vue'),
      },
      {
        name: 'agentPower',
        path: '/agent/agentPower',
        meta: { title: '下级权益订单', parentTitle: '首页' },
        component: () => import('@/views/agent/agentPower/agentPower.vue'),
      },
    ],
  },
  {
    name: 'parkNotice',
    path: '/parkNotice',
    redirect: '/parkNotice/bath',
    meta: { title: '园区公告', icon: 'assets-slide-list' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'parkNoticebath',
        path: '/parkNotice/bath',
        meta: { title: '园区公告', parentTitle: '首页' },
        component: () => import('@/views/parkNotice/parkNotice.vue'),
      },
    ],
  },
  {
    name: 'parkPower',
    path: '/parkPower',
    redirect: '/parkPower/bath',
    meta: { title: '权益订单', icon: 'assets-slide-list' },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'parkPowerbath',
        path: '/parkPower/bath',
        meta: { title: '权益订单', parentTitle: '首页' },
        component: () => import('@/views/power/power.vue'),
      },
    ],
  },
  {
    name: 'parkDesc',
    path: '/parkDesc',
    redirect: '/parkDesc/bath',
    meta: { title: '园区简介', hidden: true },
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        name: 'parkDescBath',
        path: '/parkDesc/bath',
        meta: { title: '园区简介', parentTitle: '首页' },
        component: () => import('@/views/parkDesc/parkDesc.vue'),
      },
    ],
  },
];
