const ignoreCacheRoutes = ['login', 'dashboardbath'];
const localTabsRouter = CircularJSON.parse(localStorage.getItem('tabsRouter') as string);
import CircularJSON from 'circular-json';
const state = {
  tabRouterList: localTabsRouter,
  currentPath: '',
};
const getters = {};
const mutations = {
  ADD_TABS_ROUTER(state: any, value: any) {
    const needAlive = !ignoreCacheRoutes.includes(value.name as string);
    if (needAlive) {
      if (!state.tabRouterList?.find((route: any) => route.path == value.path)) {
        const newTab = state.tabRouterList.concat({ ...value });
        state.tabRouterList = newTab;
        localStorage.setItem('tabsRouter', CircularJSON.stringify(state.tabRouterList));
        state.currentPath = value.path;
      }
    }
  },
  DELETE_TABS_ROUTER(state: any, value: any) {
    state.tabRouterList.splice(value.index - 1, 1);
    localStorage.setItem('tabsRouter', CircularJSON.stringify(state.tabRouterList));
  },
  DELETE_TABS_ROUTER_BYPATH(state: any, value: any) {
    const newTabrouter = state.tabRouterList.filter((route: any) => route.path != value.path);
    state.tabRouterList = newTabrouter;
    localStorage.setItem('tabsRouter', CircularJSON.stringify(state.tabRouterList));
  },
  CLEAR_TABS_ROUTER(state: any) {
    state.tabRouterList = [];
    localStorage.removeItem('tabsRouter');
  },
};
const actions = {
  // 添加tabs路由
  addTabsRouter(context: any, newRoute: any) {
    context.commit('ADD_TABS_ROUTER', newRoute);
  },
  //删除tabs路由
  deleteTabsRouter(context: any, routePath: any) {
    context.commit('DELETE_TABS_ROUTER', routePath);
  },
  // 清空tabs路由
  clearTabsRouter(context: any) {
    context.commit('CLEAR_TABS_ROUTER');
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
