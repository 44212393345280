import { createStore } from 'vuex';
import { toLogin } from '@/apis/login';
import tabRouterStore from './modules/tabs-router';
import personalStep from './modules/personalStep';
import parkDescStore from './modules/parkdesc';
export default createStore({
  state: {
    userInfo: {},
  },
  getters: {},
  mutations: {
    SET_USERINFO(state, value) {
      state.userInfo = value;
      localStorage.setItem('userInfo', JSON.stringify(value));
    },
    CLEAR_ALL_LOCALSTORAGE(state) {
      state.userInfo = {};
      localStorage.clear();
    },
  },
  actions: {
    loginAction(context, requestData) {
      return new Promise((resolve, reject) => {
        toLogin(requestData)
          .then((res: any) => {
            context.commit('SET_USERINFO', res);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {
    tabRouterStore,
    personalStep,
    parkDescStore,
  },
});
