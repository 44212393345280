import router from './index';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
NProgress.configure({ showSpinner: false });
// 全局前置守卫
router.beforeEach((to) => {
  NProgress.start();
  const token = JSON.parse(localStorage.getItem('userInfo') as string)?.token;
  if (!token) {
    if (to.name !== 'Login') {
      return {
        name: 'Login',
      };
    }
  }
});
// 全局后置守卫
router.afterEach(() => {
  NProgress.done();
  document.querySelector('body')?.setAttribute('style', 'overflow: auto !important;');
});
