import { createCompanyType } from '@/apis/serve';
const state = {
  companyData: JSON.parse(localStorage.getItem('companyData') as string),
  parkData: JSON.parse(localStorage.getItem('parkData') as string),
  companyDetail: {},
};
const getters = {};
const mutations = {
  SET_COMPANYDATA(state: any, value: any) {
    state.companyData = value;
    localStorage.setItem('companyData', JSON.stringify(value));
  },
  SET_PARKDATA(state: any, value: any) {
    state.parkData = value;
    localStorage.setItem('parkData', JSON.stringify(value));
  },
  SET_COMPANY_DETAIL(state: any, value: any) {
    state.companyDetail = value;
  },
};
const actions = {
  GetCreateCompanyType(context: any, requestData: any) {
    return new Promise((resolve, reject) => {
      context.commit('SET_PARKDATA', requestData);
      createCompanyType(requestData)
        .then((res: any) => {
          context.commit('SET_COMPANYDATA', res.data);
          // context.commit('SET_COMPANYDATA', { company_id: '116', company_type: 1 });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
