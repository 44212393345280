import { http } from '@/utils/request';

interface Login {
  username: string;
  password: string;
}

export function toLogin(data: Login) {
  return http.request({
    url: '/common/login',
    data,
  });
}

// 刷新登录授权码
export function toUploadToken(token: string) {
  const data = {
    token: token,
  };
  return http.request({
    url: '/common/refresh',
    data: data,
  });
}
