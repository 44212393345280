import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import otherRoutes from '@/router/modules/admin';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'Login',
    meta: { hidden: true },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { hidden: true },
    component: () => import('@/views/Login/index.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...otherRoutes],
});

export default router;
export { routes };
