import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { MessagePlugin } from 'tdesign-vue-next';
import { toUploadToken } from '@/apis/login';
import router from '@/router';
import axiosRetry from 'axios-retry';
class Axios {
  private instance;
  constructor(config: AxiosRequestConfig) {
    this.instance = axios.create(config);
    this.interceptors();
    this.reRequest();
  }

  public async request(config: AxiosRequestConfig) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.instance.request(config);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  private reRequest() {
    //请求错误时重新发送请求
    axiosRetry(this.instance, {
      retries: 1, //设置自动发送请求次数
      retryDelay: (retryCount = 1) => {
        return retryCount * 1000; // 重复请求延迟
      },
      shouldResetTimeout: true, //  重置超时时间
      retryCondition: (error: any) => {
        //true为打开自动发送请求，false为关闭自动发送请求
        if (error.response && error.response.data) {
          // 签名过期需要刷新登录授权码
          if (error.response.data.code === 90004) {
            const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
            toUploadToken(userInfo.token).then((res: any) => {
              const newUserInfo = { ...userInfo, token: res.token };
              localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
            });
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    });
  }
  private interceptors() {
    this.interceptorsRequest();
    this.interceptorsResponse();
  }
  private interceptorsRequest() {
    // 添加请求拦截器
    this.instance.interceptors.request.use(
      (config) => {
        // 在发送请求之前做些什么
        const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
        if (config && config.headers && userInfo) {
          config.headers['authorization'] = userInfo.token;
        }
        return config;
      },
      (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
      },
    );
  }
  private interceptorsResponse() {
    // 添加响应拦截器
    this.instance.interceptors.response.use(
      (response) => {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        return response;
      },
      (error) => {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        if (error.response.data && error.response.data.code === 90004) {
          return Promise.reject(error);
        } else if (error.response.data.code === 90002) {
          const loginInfo: any = localStorage.getItem('loginInfo');
          localStorage.clear();
          localStorage.setItem('loginInfo', loginInfo);
          router.push({
            path: '/login',
          });
          return;
        } else if (error.response.data.msg) {
          MessagePlugin.error({ content: error.response.data.msg });
        }
        return Promise.reject(error);
      },
    );
  }
}
const http = new Axios({
  baseURL: process.env.VUE_APP_API,
  timeout: 20000,
  method: 'post',
});

export { http };
