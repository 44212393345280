import { http } from '@/utils/request';

interface GetProductList {
  park_id: string;
}

interface CreateCompanyType {
  park_id: number;
  product_type: number;
}

interface CompanyListData {
  page: number | string;
  limit: number | string;
  keywords: string;
  product_type: string;
}

export function toGetParkList() {
  return http.request({
    url: '/company/parks',
  });
}

export function toGetProductListByParkId(data: GetProductList) {
  return http.request({
    url: '/company/registerProducts',
    data,
  });
}

// 企业类型创建数据
export function createCompanyType(data: CreateCompanyType) {
  return http.request({
    url: '/company/createCompanyType',
    data,
  });
}

// 企业列表
export function getCompanyList(data: CompanyListData) {
  return http.request({
    url: '/company/list',
    data,
  });
}

// 企业申请开户
export function accountCreate(data: { company_id: string | number }) {
  return http.request({
    url: '/CompanyAccount/create',
    data,
  });
}

// 企业注销
export function companyLogout(data: { company_id: string | number }) {
  return http.request({
    url: '/CompanyClose/create',
    data,
  });
}

//开票提额
export function companyIncrease(data: { company_id: string | number; amount: string | number; contract_url: string }) {
  return http.request({
    url: '/CompanyIncrease/apply',
    data,
  });
}

//获取开票提额申请列表
export function companyIncreaseList(data: { page: string | number; limit: string | number }) {
  return http.request({
    url: '/CompanyIncrease/list',
    data,
  });
}

//获取企业注册产品类型
export function companyRegisterProductType(data: { type: 1 }) {
  return http.request({
    url: '/company/getRegisterProductTypes',
    data,
  });
}
