const state = {
  parkDesc: '7888',
};
const getters = {};
const mutations = {
  SET_PARKDESC(state: any, value: any) {
    state.parkDesc = value;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
