import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import TDesign from 'tdesign-vue-next';
// import 'amfe-flexible/index.js';
// 引入组件库全局样式资源
import 'tdesign-vue-next/es/style/index.css';

// 全局svg
import SvgIcon from '@/components/svgIcon/index.vue';
import '@/components/svgIcon/svg';

// 路由守卫
import '@/router/permit';
createApp(App).use(store).use(router).use(TDesign).component('svg-icon', SvgIcon).mount('#app');
